import posthog from 'posthog-js';

if (typeof window !== 'undefined') {
  posthog.init('phc_cCVwtLOYMSuire9tY58rrunmOwaV3cZjDCg3cTohdNO', {
    api_host: 'https://app.posthog.com',
  });
}

export function captureWithPostHog(eventName, posthogConfig) {
  if (typeof window !== 'undefined') {
    posthog.capture(eventName, posthogConfig);
  }
}

export default posthog;
