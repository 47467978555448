import { trackWithMixPanel } from '../mixpanel';
import { captureWithPostHog } from '../posthog';
import { MixPanelEvents, PostHogEvents } from '../../constants';

export function signUp(callback, config, posthogConfig) {
  trackWithMixPanel(MixPanelEvents.SignUp, {
    config,
  });
  captureWithPostHog(PostHogEvents.SignUp, posthogConfig);
  if (typeof callback === 'function') callback();
}

export function loginWithGoogle(config, posthogConfig) {
  trackWithMixPanel(MixPanelEvents.GoogleSignIn, config);
  captureWithPostHog(PostHogEvents.GoogleSignIn, posthogConfig);
  window.location.assign(
    'https://web.ruttl.com/auth/google?redirect_url=https://web.ruttl.com',
  );
}

export function btnOnClick({
  callback,
  to,
  mixPanelEvent,
  posthogEvent,
  config,
  posthogConfig,
}) {
  // eslint-disable-next-line func-names
  return function () {
    trackWithMixPanel(mixPanelEvent, config);
    captureWithPostHog(posthogEvent, posthogConfig);
    if (typeof to === 'string') window.location.assign(to);
    if (typeof callback === 'function') callback();
  };
}
