// grab the Mixpanel factory
import mixpanel from 'mixpanel-browser';

// create an instance of the mixpanel client
mixpanel.init('8a961362d3ee765f9b7482de41541a56');

// function source() {
//   if (document.referrer.search('https?://(.*)google.([^/?]*)') === 0) {
//     return 'Google';
//   } else if (document.referrer.search('https?://(.*)bing.([^/?]*)') === 0) {
//     return 'Bing';
//   } else if (document.referrer.search('https?://(.*)yahoo.([^/?]*)') === 0) {
//     return 'Yahoo';
//   } else if (document.referrer.search('https?://(.*)facebook.([^/?]*)') === 0) {
//     return 'Facebook';
//   } else if (document.referrer.search('https?://(.*)twitter.([^/?]*)') === 0) {
//     return 'Twitter';
//   } else {
//     return 'Other';
//   }
// }

// if (mixpanel) {
//   mixpanel.register_once({ Source: source() });
//   fetch('http://ip-api.com/json')
//     .then((res) => res.json())
//     .then((data) => {
//       if (data.country) {
//         console.log(data.country);
//         mixpanel.register_once({ Source: data.country });
//         mixpanel.people.set_once({ Source: data.country });
//       }
//     });
//   mixpanel.people.set_once({ Source: source() });
// }

export function trackWithMixPanel(eventName, config) {
  mixpanel.track(eventName, config);
}

export default mixpanel;
