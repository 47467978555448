import React from 'react';

// import List from './SocialLinks.styled';

const SocialLinks = () => (
  <ul className="styled-ul-social-link">
    {/* <li>
      <a
        href="https://www.instagram.com/ruttlapp/"
        target="_blank"
        rel="noopener noreferrer">
        <img
          src="/assets/img/social/instagram.svg"
          alt="instagram"
          title="instagram"
        />
      </a>
    </li>
    <li>
      <a
        href="https://www.facebook.com/ruttlapp/"
        target="_blank"
        rel="noopener noreferrer">
        <img
          src="/assets/img/social/facebook.svg"
          alt="facebook"
          title="facebook"
        />
      </a>
    </li> */}
    <li style={{ marginLeft: 0 }}>
      <a
        href="https://www.youtube.com/channel/UCGsHNDVsWz93sCp2p3u_QhQ"
        target="_blank"
        rel="noopener noreferrer">
        <img
          src="/assets/img/social/youtube.svg"
          alt="youtube"
          title="youtube"
        />
      </a>
    </li>
    <li>
      <a
        href="https://www.linkedin.com/company/ruttl"
        target="_blank"
        rel="noopener noreferrer">
        <img
          src="/assets/img/social/linkedin.svg"
          alt="linkedin"
          title="linkedin"
        />
      </a>
    </li>
    <li>
      <a
        href="https://twitter.com/RuttlApp"
        target="_blank"
        rel="noopener noreferrer">
        <img
          src="/assets/img/social/twitter-circle-black.svg"
          alt="twitter"
          title="twitter"
        />
      </a>
    </li>
    {/* <li>
      <a
        href="https://pin.it/6JQzNQR"
        target="_blank"
        rel="noopener noreferrer">
        <img
          src="/assets/img/social/pinterest.svg"
          alt="pinterest"
          title="pinterest"
        />
      </a>
    </li> */}
    <li>
      <a
        href="https://join.slack.com/t/ruttlcommunity/shared_invite/zt-p4r090hy-Ya7pxDgCfD4kYnzVJRNovQ"
        target="_blank"
        rel="noopener noreferrer">
        <img src="/assets/img/social/slack.svg" alt="slack" title="slack" />
      </a>
    </li>
  </ul>
);

export default SocialLinks;
