export const MixPanelEvents = {
  GoogleSignIn: 'GoogleSignIn',
  PageView: 'PageView',
  SignUp: 'SignUp',
  ButtonClick: 'ButtonClick',
};
export const MixPanelButtons = {
  GoogleSignIn: 'GoogleSignIn',
  SignUp: 'SignUp',
  TryDemo: 'TryDemo',
  ExploreMore: 'ExploreMore',
  GetStarted: 'GetStarted',
  ViewMore: 'ViewMore',
  TryForFree: 'TryForFree',
  LogIn: 'LogIn',
  Coupon: 'Coupon',
  SupportUs: 'SupportUs',
  LearnMore: 'LearnMore',
};

export const MixPanelButtonContext = {
  // homepage
  ProductHuntTop: 'Product Hunt button at the top center of hero section',
  SignUpWithGoogleTop: 'Sign Up free with Google button at the hero section',
  TryDemoTop: 'Try Demo button at the hero section',
  WatchTheDemo: 'Watch the Demo button below video',
  ExploreMore: 'Explore More button at top of features section',
  GetStartedFeatures: 'Get Started button at top of features section',
  CommentBtn: 'Comment Mode button at top of features section',
  EditBtn: 'Edit Mode button at top of features section',
  BugTrackingBtn: 'Bug Tracking Mode button at top of features section',
  Feature1: 'Button at feature 1 in features section',
  Feature2: 'Button at feature 2 in features section',
  Feature3: 'Button at feature 3 in features section',

  // SocialProof component
  GetStartedSocialProofBtn: 'Get Started button at award winning section',

  // feedback component
  Feedback1: 'Button at feedback 1 in feedback section',
  Feedback2: 'Button at feedback 2 in feedback section',
  Feedback3: 'Button at feedback 3 in feedback section',
  Feedback4: 'Button at feedback 4 in feedback section',

  // get started center component
  GetStartedBot: 'Get Started for free button in Get Started section',
  TryDemoBot: 'Try Demo button in Get Started section',

  // testimonial component
  Testimonial1: 'Testimonial button 1 at testimonial section',
  Testimonial2: 'Testimonial button 2 at testimonial section',
  Testimonial3: 'Testimonial button 3 at testimonial section',

  // integration component
  ViewIntegrations: 'View Integrations button at integrations section',

  // faq component
  FaqViewMore: 'View more button in Faq section',

  // header
  TryForFree: 'Try for free button in header',
  LogIn: 'Log in button in header',
  Coupon: 'Coupon on header',
  SupportUs: 'Support Us button in top banner',

  // sidebar
  TryDemoSidebar: 'Try Demo button on sidebar',
};

export const PostHogEvents = {
  GoogleSignIn: 'GoogleSignIn',
  PageView: 'PageView',
  SignUp: 'SignUp',
  ButtonClick: 'ButtonClick',
};
export const PostHogButtons = {
  GoogleSignIn: 'GoogleSignIn',
  SignUp: 'SignUp',
  TryDemo: 'TryDemo',
  ExploreMore: 'ExploreMore',
  GetStarted: 'GetStarted',
  ViewMore: 'ViewMore',
  TryForFree: 'TryForFree',
  LogIn: 'LogIn',
  Coupon: 'Coupon',
  SupportUs: 'SupportUs',
  LearnMore: 'LearnMore',
  ChooseAProduct: 'ChooseAProduct',
};

export const PostHogButtonContext = {
  // homepage
  ProductHuntTop: 'Product Hunt button at the top center of hero section',
  SignUpWithGoogleTop: 'Sign Up free with Google button at the hero section',
  TryDemoTop: 'Try Demo button at the hero section',
  WatchTheDemo: 'Watch the Demo button below video',
  ExploreMore: 'Explore More button at top of features section',
  GetStartedFeatures: 'Get Started button at top of features section',
  CommentBtn: 'Comment Mode button at top of features section',
  EditBtn: 'Edit Mode button at top of features section',
  BugTrackingBtn: 'Bug Tracking Mode button at top of features section',
  Feature1: 'Button at feature 1 in features section',
  Feature2: 'Button at feature 2 in features section',
  Feature3: 'Button at feature 3 in features section',
  ChooseAProductBtn: 'Help me choose a product button in questionnaire section',

  // SocialProof component
  GetStartedSocialProofBtn: 'Get Started button at award winning section',

  // feedback component
  Feedback1: 'Button at feedback 1 in feedback section',
  Feedback2: 'Button at feedback 2 in feedback section',
  Feedback3: 'Button at feedback 3 in feedback section',
  Feedback4: 'Button at feedback 4 in feedback section',

  // get started center component
  GetStartedBot: 'Get Started for free button in Get Started section',
  TryDemoBot: 'Try Demo button in Get Started section',

  // testimonial component
  Testimonial1: 'Testimonial button 1 at testimonial section',
  Testimonial2: 'Testimonial button 2 at testimonial section',
  Testimonial3: 'Testimonial button 3 at testimonial section',

  // integration component
  ViewIntegrations: 'View Integrations button at integrations section',

  // faq component
  FaqViewMore: 'View more button in Faq section',

  // header
  TryForFree: 'Try for free button in header',
  LogIn: 'Log in button in header',
  Coupon: 'Coupon on header',
  SupportUs: 'Support Us button in top banner',

  // sidebar
  TryDemoSidebar: 'Try Demo button on sidebar',
};
