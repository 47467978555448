import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { ThemeProvider } from 'styled-components';
import Helmet from 'react-helmet';

// import GlobalStyles from 'styles/globalStyles';
// import theme from 'styles/theme';

import Header from 'components/Header';
import Footer from 'components/Footer';
import ContactUs from 'components/ContactUs';
// import { Link } from 'gatsby';
// import SidebarSticky from '../SidebarSticky';
import { trackWithMixPanel } from '../../utils/mixpanel';
import { captureWithPostHog } from '../../utils/posthog';
import { MixPanelEvents, PostHogEvents } from '../../constants';
import SignupModal from '../SignupModal/SignupModal';
import 'styles/master.scss';
import useScrollDirection from '../../hooks/ScrollDirection';

const Template = ({
  children,
  hideHeader,
  hideFooter,
  // showBottomPopup = true,
  hideTopBar,
  productPage,
  isForPage,
  showStickyBottomBanner,
  pricingPage,
  product,
  getStartedPage,
  homepage,
  blog,
  location = {},
}) => {
  const [showContactUs, setShowContactUs] = useState(false);
  const [defaultContactSubject, setContactSubject] = useState('');
  const [showSignup, setShowSignup] = useState(false);
  const [headerTop, setheaderTop] = useState(true);

  const scrollDirection = useScrollDirection();

  const toggleContactUs = (payLoad) => {
    setShowContactUs((state) => !state);
    setContactSubject(payLoad || '');
  };

  const toggleSignup = () => setShowSignup((state) => !state);

  useEffect(() => {
    trackWithMixPanel(MixPanelEvents.PageView, {
      pathName: typeof window !== 'undefined' ? location.pathname : '',
    });
    captureWithPostHog(PostHogEvents.PageView, {
      pathName: typeof window !== 'undefined' ? location.pathname : '',
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset <= 0) {
        setheaderTop(true);
      } else {
        setheaderTop(false);
      }
    });
  }, []);

  useEffect(() => {
    const documentGlobal = typeof document !== 'undefined' && document;
    (() => {
      const sc = documentGlobal.createElement('script');
      sc.type = 'text/javascript';
      sc.async = true;
      sc.src = 'https://b.sf-syn.com/badge_js?sf_id=3367866&variant_id=sf';
      const p = documentGlobal.getElementsByTagName('script')[0];
      p.parentNode.insertBefore(sc, p);
    })();
  }, []);

  return (
    // <ThemeProvider theme={theme}>
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preload"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap"
          as="style"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
        <link
          rel="preload"
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap"
          as="style"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap"
          rel="stylesheet"
        />

        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/assets/img/icons/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/assets/img/icons/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/assets/img/icons/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/assets/img/icons/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/assets/img/icons/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/assets/img/icons/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/assets/img/icons/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/assets/img/icons/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/img/icons/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/assets/img/icons/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/img/icons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/assets/img/icons/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/img/icons/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="/assets/img/icons/ms-icon-144x144.png"
        />
        <meta name="theme-color" content="#ffffff" />
        {/* <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          rel="preload"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap"
          as="style"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        /> */}
      </Helmet>
      {/* <GlobalStyles /> */}
      {!hideHeader && (
        <Header
          toggleContactUs={toggleContactUs}
          // showBottomPopup={showBottomPopup}
          hideTopBar={hideTopBar}
          pricingPage={pricingPage}
          productPage={productPage}
          isForPage={isForPage}
          toggleSignup={toggleSignup}
          getStartedPage={getStartedPage}
          homepage={homepage}
          blog={blog}
        />
      )}
      {/* <SidebarSticky /> */}
      {typeof children === 'function'
        ? children({ toggleContactUs, showContactUs, toggleSignup })
        : children}
      {!hideFooter && (
        <Footer
          toggleContactUs={toggleContactUs}
          toggleSignup={toggleSignup}
          location={location}
        />
      )}

      {showStickyBottomBanner ? (
        <div
          className={`sticky-bug-tracking-banner flex 
          ${scrollDirection === 'down' ? 'show' : 'hide'} 
          ${headerTop ? 'top' : ''}`}>
          <div className="bug-wrapper">
            <img
              loading="lazy"
              src="/assets/img/bug-report-icon.svg"
              alt="Bug Icon"
              title="Bug Icon"
            />
          </div>
          <p className="para">Track bugs faster than Jira</p>
          <a
            href="https://smashbugs.ruttl.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="button">
            Play Game
          </a>
        </div>
      ) : (
        ''
      )}

      <button
        type="button"
        className="chat-button"
        onClick={() => setShowContactUs(true)}>
        <div className="flex">
          <svg
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            style={{ height: '24px', width: '24px' }}>
            <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
          </svg>
        </div>
      </button>

      {showContactUs && (
        <ContactUs
          show={showContactUs}
          onDismiss={toggleContactUs}
          defaultSubject={defaultContactSubject}
        />
      )}
      {showSignup && (
        <SignupModal product={product} toggleModal={toggleSignup} />
      )}
    </>
    // </ThemeProvider>
  );
};

Template.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  hideHeader: PropTypes.bool,
  hideFooter: PropTypes.bool,
  showStickyBottomBanner: PropTypes.bool,
  // showBottomPopup: PropTypes.bool,
  isForPage: PropTypes.bool,
  hideTopBar: PropTypes.bool,
  pricingPage: PropTypes.bool,
  productPage: PropTypes.bool,
  location: PropTypes.object,
  product: PropTypes.string,
  getStartedPage: PropTypes.bool,
  homepage: PropTypes.bool,
  blog: PropTypes.bool,
};

export default Template;
