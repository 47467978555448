import React, { useState, useRef } from 'react';
import axios from 'axios';
import isEmail from 'validator/lib/isEmail';

// import { colors } from 'styles/theme';

const SignupForm = () => {
  const [input, setInput] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);
  const [signupSuccess, setSignupSuccess] = useState(false);

  const signupSubmitButton = useRef();

  const inputChangeHandler = ({ target: { name, value } }) =>
    setInput((state) => ({ ...state, [name]: value }));

  const signupHandler = (e) => {
    e.preventDefault();

    setError(null);

    if (input.email.length === 0) {
      setError('Error: Email not entered.');
      return;
    }
    if (!isEmail(input.email)) {
      setError('Error: Email is not valid.');
      return;
    }
    if (input.password.length < 6) {
      setError('Error: Password should be 6 characters or longer.');
      return;
    }

    signupSubmitButton.current.disabled = true;
    signupSubmitButton.current.innerHTML = 'Signing up...';

    axios
      .post('https://us-central1-ruttlp.cloudfunctions.net/createUser', {
        ...input,
        // ...(new URL(window.location.href).searchParams.get('ref')
        //   ? { ref: new URL(window.location.href).searchParams.get('ref') }
        //   : { ref: 'website-feedback-tool' }),
      })
      .then(() => {
        setSignupSuccess(true);
        setInput({ email: '', password: '' });
        setError(null);
        signupSubmitButton.current.disabled = false;
        signupSubmitButton.current.innerHTML = 'Sign Up';
        if (window.dataLayer)
          window.dataLayer.push({
            event: 'app-new-signup',
          });
      })
      .catch((err) => {
        if (err?.response?.data) setError(err.response.data);
        else setError(err.toString());
        signupSubmitButton.current.disabled = false;
        signupSubmitButton.current.innerHTML = 'Sign Up';
      });
  };

  return (
    <form onSubmit={signupHandler}>
      <div className="form-group">
        <label htmlFor="signup-email">Email Address</label>
        <input
          type="email"
          id="signup-email"
          name="email"
          value={input.email}
          onChange={inputChangeHandler}
          placeholder="Enter email"
        />
      </div>
      <div className="form-group">
        <label htmlFor="signup-password">Password</label>
        <input
          type="password"
          id="signup-password"
          name="password"
          value={input.password}
          onChange={inputChangeHandler}
          placeholder="Enter password"
        />
      </div>
      <div className="form-group">
        <button type="submit" className="button" ref={signupSubmitButton}>
          Create account
        </button>
      </div>
      {error && (
        <p
          className="red"
          style={{
            // color: colors.red,
            fontSize: 16,
          }}>
          {error}
        </p>
      )}
      {signupSuccess && (
        <p
          className="green"
          style={{
            // color: colors.green,
            fontSize: 16,
          }}>
          Success! Check your email for the verification email &amp; start using
          ruttl.
        </p>
      )}
    </form>
  );
};

export default SignupForm;
