import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const Meta = ({
  title = 'ruttl',
  description = 'ruttl is a website reviewing tool that provides the easiest way to leave comments and edit live websites. Invite stakeholders to give precise, contextual feedbacks for pixel-perfect designs.',
  author = null,
  image = 'https://ruttl.com/assets/img/ruttl-social.png',
  url,
  date,
}) => (
  <Helmet title={title}>
    <meta name="description" content={description} />
    {author && <meta name="article-author" content={author} />}

    <meta property="og:type" content="article" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="og:site_name" content="ruttl" />
    <meta property="og:url" content={url} />
    <meta
      property="article:publisher"
      content="https://www.facebook.com/ruttlapp/"
    />
    {date && <meta property="article:published_time" content={date} />}

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:url" content={url} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image:src" content={image} />
    <meta name="twitter:site" content="@ruttlapp" />
    <meta name="p:domain_verify" content="ec6088ac99821bf5f2f6bc56a04c4e79" />
    <link rel="canonical" href={url} />
    <meta
      name="robots"
      content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
    />
  </Helmet>
);

Meta.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  author: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string.isRequired,
  date: PropTypes.string,
};

export default Meta;
